import request from '@/service'
import { Account, AllDictItemResult, DictItemParams, DictItemResult, IRootListsParams, IRootListsResult, LoginResult, UserInfo, VerifyCodeData, VerifyCodeRes } from './types'
import { BasicResult } from '../../request/types'
import { LoginApi } from './constant'

// 登录
export const login = (data: Account) => {
  return request.post<BasicResult<LoginResult>>({
    url: LoginApi.login,
    data
  })
}

// 个人信息
export const getUserInfo = () => {
  return request.get<BasicResult<UserInfo>>({
    url: LoginApi.getUserInfo,
    showLoading: false
  })
}

// 验证码
export const getVerifyCode = (data: VerifyCodeData) => {
  return request.post<VerifyCodeRes>({
    url: LoginApi.getVerifyCode,
    data
  })
}

// 获取字典值
export const getDictItem = (params: DictItemParams) => {
  return request.get<BasicResult<DictItemResult>>({
    url: LoginApi.getDictItem,
    params
  })
}

// 获取所有字典值
export const getQueryAllDictItems = () => {
  return request.get<BasicResult<AllDictItemResult>>({
    url: LoginApi.getQeryAllDictItems,
    showLoading: false
  })
}

// 获取字典分类
export const getRootlists = (params: IRootListsParams) => {
  return request.get<BasicResult<IRootListsResult>>({
    url: LoginApi.getRootList,
    params
  })
}
