import { INavigator } from '@/service/api/order/types'

export const blobExport = (data: Blob, name: string) => {
  const navigator: INavigator = window.navigator
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(new Blob([data], { type: 'application/vnd.ms-excel' }), name + '.xls')
  } else {
    const url = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.ms-excel' }))
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', name + '.xls')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }
}
