export function isExternal(path: string) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

export function checkTime(time: string) {
  const nowTime = new Date().getTime()
  const selectTime = new Date(time).getTime()
  console.log(selectTime)
  return selectTime > nowTime
}
