import { getItem, setItem } from '@/utils/storage'
import { MAIN_COLOR, DEFAULT_COLOR, T_COLOR, DEFAULT_T_COLOR, MENU_COLOR, DEFAULT_MENU_COLOR } from '@/constant'
import { IThemeState } from './types'
import variables from '@/styles/variables.module.scss'
export default {
  namespaced: true,
  state: () => ({
    mainColor: getItem(MAIN_COLOR) || DEFAULT_COLOR,
    tColor: getItem(T_COLOR) || DEFAULT_T_COLOR,
    menuColor: getItem(MENU_COLOR) || DEFAULT_MENU_COLOR,
    variables
  }),
  mutations: {
    setMainColor(state: IThemeState, newColor: string) {
      state.mainColor = newColor
      setItem(MAIN_COLOR, newColor)
    },
    setTColor(state: IThemeState, newColor: string) {
      state.tColor = newColor
      state.variables.menuText = newColor
      setItem(T_COLOR, newColor)
    },
    setMenuColor(state: IThemeState, newColor: string) {
      state.menuColor = newColor
      state.variables.menuBg = newColor
      setItem(MENU_COLOR, newColor)
    }
  }
}
