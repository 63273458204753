import { IAppState } from './types'
import { TAGS_VIEW } from '@/constant/index'
import { setItem, getItem } from '@/utils/storage'
import { ITag } from '@/components/Tagsview/types'
import router from '@/router'

export default {
  namespaced: true,
  state: () => ({
    sidebarOpened: true,
    tagsViewList: getItem(TAGS_VIEW) || [],
    language: getItem('language') || 'zhCn'
  }),
  mutations: {
    /**
     * 设置语言
     */
    setLanguage(state: IAppState, language: string) {
      state.language = language
      setItem('language', state.language)
    },
    triggerSidebarOpened(state: IAppState) {
      state.sidebarOpened = !state.sidebarOpened
    },
    /**
     * 添加 tags
     */
    addTagsViewList(state: IAppState, tag: ITag) {
      const isFind = state.tagsViewList.find((item: ITag) => {
        return item.path === tag.path
      })
      // 处理重复
      if (!isFind) {
        state.tagsViewList.push(tag)
        setItem(TAGS_VIEW, state.tagsViewList)
      }
    },
    /**
     * 为指定的 tag 修改 title
     */
    changeTagsView(state: IAppState, { index, tag }: { index: number, tag: ITag }) {
      state.tagsViewList[index] = tag
      setItem(TAGS_VIEW, state.tagsViewList)
    },
    /**
     * 删除 tag
     * @param {type: 'other'||'right'||'index', index: index} payload
     */
    removeTagsView(state: IAppState, payload: { type: string, index: number }) {
      if (payload.type === 'index') {
        state.tagsViewList.splice(payload.index, 1)
      } else if (payload.type === 'other') {
        state.tagsViewList.splice(
          payload.index + 1,
          state.tagsViewList.length - payload.index + 1
        )
        state.tagsViewList.splice(0, payload.index)
        router.push(state.tagsViewList[payload.index].fullPath)
      } else if (payload.type === 'right') {
        state.tagsViewList.splice(
          payload.index + 1,
          state.tagsViewList.length - payload.index + 1
        )
        router.push(state.tagsViewList[payload.index].fullPath)
      }
      setItem(TAGS_VIEW, state.tagsViewList)
    }
  },
  actions: {}
}
