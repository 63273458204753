export const TOKEN = 'token'
// appstype
export const APPS_TYPE = '7'
// token 时间戳
export const TIME_STAMP = 'timeStamp'
// 超时时长(毫秒) 一个月
export const TOKEN_TIMEOUT_VALUE = 720 * 3600 * 1000
// 主题色保存的 key
export const MAIN_COLOR = 'mainColor'
// 默认色值
export const DEFAULT_COLOR = '#409eff'
// 菜单文字颜色保存的 key
export const T_COLOR = 'tColor'
// 菜单文字颜色默认色值
export const DEFAULT_T_COLOR = '#bfcbd9'
// 菜单颜色保存的 key
export const MENU_COLOR = 'menuColor'
// 菜单颜色默认色值
export const DEFAULT_MENU_COLOR = '#304155'
// tags
export const TAGS_VIEW = 'tagsView'
// 手机正则
export const MOBILE_REGX = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
// 页码
export const PAGE_NUM = 1
// 单页数据条数
export const PAGE_SIZE = 20
// 单页数据条数-最大值
export const PAGE_BIG_SIZE = 1000
// 表格列宽度-1...
export const COLUMN_ONE = 100
export const COLUMN_TWO = 150
export const COLUMN_THREE = 200
export const COLUMN_FOUR = 250
// 订单状态数组字典值
export const ORDER_STATUS_ID = 1555444564158971906n
//
export const EQUIPMENT_BRAND = 1449950262209810434n
//
export const EQUIPMENT_MODEL = 1413329033030012930n
// 发票状态（2-开票申请，3-已开票列表）
export enum InvoiceState {
  invoice = 2,
  invoiced = 3
}
export const BILLING_STATUS_INVOICE = 1
// 高德key
export const MAP_KEY = 'c5dc8231833f0a99386f9f77c449a9d7'
// 银行 公司 收款账户
export const BANK = '工行浦东开发区曹路支行'
export const ENTNAME = '上海港机汇软件科技有限公司'
export const RECACT = '1001076609100045571'
