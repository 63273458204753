import { createStore } from 'vuex'
import getters from './getters'
import user from './modules/user'
import app from './modules/app'
import theme from './modules/theme'
import order from './modules/order'
import invoice from './modules/invoice'
import invoiced from './modules/invoiced'

export default createStore({
  getters,
  modules: {
    user,
    app,
    theme,
    invoice,
    order,
    invoiced
  }
})
