import { login, getUserInfo, getQueryAllDictItems, getRootlists } from '@/service/api/sys'
import { setItem, getItem, removeAllItem } from '@/utils/storage'
import { TOKEN } from '@/constant'
import { IUserState } from './types'
import { Account, AllDictItemResult, IRootListsParams, IRootListsResult, LoginResult, UserInfo } from '@/service/api/sys/types'
import { BasicResult } from '@/service/request/types'
import router from '@/router'
import { setTimeStamp } from '@/utils/auth'

export default {
  namespaced: true,
  state: () => ({
    token: getItem(TOKEN) || '',
    userInfo: {},
    allDictItems: [],
    rootLists: []
  }),
  mutations: {
    setToken(state: IUserState, token: string) {
      state.token = token
      setItem(TOKEN, token)
    },
    setUserInfo(state: IUserState, userInfo: UserInfo) {
      state.userInfo = userInfo
    },
    setAllDictItems(state: IUserState, allDictItems: AllDictItemResult) {
      state.allDictItems = allDictItems
    },
    setRootList(state: IUserState, rootLists: any[]) {
      state.rootLists = rootLists
    }
  },
  actions: {
    login(context: any, loginForm: Account) {
      const { mobile, verifyCode } = loginForm
      return new Promise((resolve, reject) => {
        login({
          mobile,
          verifyCode
        })
          .then((data: BasicResult<LoginResult>) => {
            context.commit('setToken', data.data.token)
            setTimeStamp()
            resolve(data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    async getUserInfo(context: any) {
      const res = await getUserInfo()
      context.commit('setUserInfo', res.data)
      return res
    },
    logout(context: any) {
      context.commit('setToken', '')
      context.commit('setUserInfo', {})
      removeAllItem()
      router.push('/login')
    },
    getAllDictItems(context: any) {
      getQueryAllDictItems().then((res: BasicResult<AllDictItemResult>) => {
        if (res.success) {
          context.commit('setAllDictItems', res.data)
        }
      })
    },
    getRootLists(context: any, params: IRootListsParams) {
      getRootlists(params).then((res: BasicResult<IRootListsResult>) => {
        if (res.success) {
          context.commit('setRootList', res.data)
        }
      })
    }
  }
}
