import { BILLING_STATUS_INVOICE, InvoiceState, PAGE_NUM, PAGE_SIZE } from '@/constant'
import { getCanInvoiceList, getInvoiceInfo, invoicedExport, invoiceExport } from '@/service/api/invoice'
import { ICanInvoiceListParams, ICanInvoiceListResult, ICanInvoiceItem, IIvoiceInfoParams, IIvoiceInfoResult, IInvoiceExParams, IInvoicedExParams } from '@/service/api/invoice/types'
import { BasicResult } from '@/service/request/types'
import { blobExport } from '@/utils/export'
import { IInvoiceState } from './types'

export default {
  namespaced: true,
  state: () => ({
    canInvoiceList: [],
    total: 100,
    loading: false,
    queryForm: {
      state: InvoiceState.invoice,
      billingStatus: undefined,
      pageNum: PAGE_NUM,
      pageSize: PAGE_SIZE
    },
    invoiceInfo: {}
  }),
  mutations: {
    setCanInvoiceList(state: IInvoiceState, canInvoiceList: ICanInvoiceItem[]) {
      state.canInvoiceList = canInvoiceList
    },
    setPageNum(state: IInvoiceState, pageNum: number) {
      state.queryForm.pageNum = pageNum
    },
    setPageSize(state: IInvoiceState, pageSize: number) {
      state.queryForm.pageSize = pageSize
    },
    setTotal(state: IInvoiceState, total: number) {
      state.total = total
    },
    setLoading(state: IInvoiceState, loading: boolean) {
      state.loading = loading
    },
    setQueryForm(state: IInvoiceState, queryForm: ICanInvoiceListParams) {
      state.queryForm = queryForm
    },
    setInvoiceInfo(state: IInvoiceState, invoiceInfo: IIvoiceInfoResult) {
      state.invoiceInfo = invoiceInfo
    }
  },
  actions: {
    async getCanInvoiceList(context: any, canInvoiceListParams: ICanInvoiceListParams) {
      context.commit('setLoading', true)
      await getCanInvoiceList(canInvoiceListParams).then((res: BasicResult<ICanInvoiceListResult>) => {
        if (res.success) {
          context.commit('setCanInvoiceList', res.data.records)
          context.commit('setPageNum', canInvoiceListParams.pageNum)
          context.commit('setPageSize', canInvoiceListParams.pageSize)
          context.commit('setTotal', res.data.total)
        }
        context.commit('setLoading', false)
      })
    },
    async getInvoiceInfo(context: any, ivoiceInfoParams: IIvoiceInfoParams) {
      await getInvoiceInfo(ivoiceInfoParams).then((res: BasicResult<IIvoiceInfoResult>) => {
        if (res.success) {
          context.commit('setInvoiceInfo', res.data)
        }
      })
    },
    async invoiceExport(context: any, params: IInvoiceExParams) {
      context.commit('setLoading', true)
      await invoiceExport(params).then((res: Blob) => {
        blobExport(res, '开票申请列表')
        context.commit('setLoading', false)
      })
    }
  }
}
