import { getItem } from '@/utils/storage'
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { ElLoading, ElMessage } from 'element-plus'
import { IData, IRequestConfig } from './types'
import { isCheckTimeout } from '@/utils/auth'
import store from '@/store'
import { APPS_TYPE } from '@/constant'

class service {
  config: AxiosRequestConfig
  instance: AxiosInstance
  showLoading: boolean
  timeId: any

  constructor(options: IRequestConfig) {
    this.config = options
    this.instance = axios.create(options)
    this.showLoading = true
    this.timeId = null
    this.setupInterceptor()
  }

  request<T>(config: IRequestConfig): Promise<T> {
    return new Promise((resolve, reject) => {
      this.instance.request<IData<any>, IData<T>>(config).then((res: any) => {
        if (res && res.data) {
          resolve(res.data)
        }
      })
        .catch((err: any) => {
          resolve(err)
        })
    })
  }

  setupInterceptor(): void {
    this.instance.interceptors.request.use((config: IRequestConfig) => {
      this.showLoading = config.showLoading !== undefined ? config.showLoading : true
      if (config.headers) {
        config.headers.appsType = APPS_TYPE
        const token = getItem('token')
        if (token) {
          if (isCheckTimeout()) {
            // 登出操作
            store.dispatch('user/logout')
            return Promise.reject(new Error('token 失效'))
          }
          config.headers['x-access-token'] = token
        }
      }

      if (this.showLoading) {
        ElLoading.service({
          lock: true,
          text: 'loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.1)',
          target: '.el-loading'
        })
      }
      return config
    })

    this.instance.interceptors.response.use((res: any) => {
      if (res.data.code === 401) {
        store.dispatch('user/logout')
      }
      if (res.data && !res.data.success && res.data.type !== 'text/xml') {
        ElMessage.error(res.data.msg)
      } else {
        clearTimeout(this.timeId)
        this.timeId = setTimeout(() => {
          if (this.showLoading && res.data.msg) {
            ElMessage.success(res.data.msg)
          }
          if (this.showLoading && !res.data.msg) {
            ElMessage.success('请求成功')
          }
        }, 1000)
      }
      return res
    }, (error: any) => {
      if (error.response && error.response.data && error.response.data.code === 401) {
        store.dispatch('user/logout')
      }
      ElMessage.error(error.message)
      return Promise.reject(error)
    })
  }

  get<T>(config: IRequestConfig): Promise<T> {
    return this.request({ ...config, method: 'GET' })
  }

  post<T>(config: IRequestConfig): Promise<T> {
    return this.request({ ...config, method: 'POST' })
  }

  delete<T>(config: IRequestConfig): Promise<T> {
    return this.request({ ...config, method: 'DELETE' })
  }

  patch<T>(config: IRequestConfig): Promise<T> {
    return this.request({ ...config, method: 'PATCH' })
  }

  put<T>(config: IRequestConfig): Promise<T> {
    return this.request({ ...config, method: 'PUT' })
  }
}

export default service
